.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  background: black;
  overflow-y: scroll;
  --secondary: rgb(209, 209, 209);
  --hover: rgb(255, 247, 247);
  --divider: #343434;
}

* {
  box-sizing: border-box;
  font-family: ".SFNSText", "SFProText-Regular", "SFUIText-Regular", ".SFUIText",
    Helvetica, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
  display: flex;
  justify-content: center;
}

.screen {
  width: 100%;
  height: 100%;
}

.container {
  max-width: 990px;
  flex: 1 1 100%;
  padding: 45px 25px;
}

h1 {
  font-weight: bold;
  color: white;
  margin: 6px 0 12px;
}

h3 {
  font-weight: bold;
  color: white;
  margin: 6px 0 12px;
}

.date {
  color: var(--secondary);
  font-size: 14px;
  text-transform: uppercase;
}

header {
  border-bottom: 1px solid var(--divider);
  position: relative;
  display: flex;
  justify-content: space-between;
}
.header-right {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
}
.header-left {}

.header-icons {
  display: flex;
  flex-direction: row;
  gap: 30px ;

}
.header-icon {
  position: relative;
  font-size: 23px;
  color: var(--secondary);
  cursor: pointer;
  transition: all ease-in-out 0.4s;
padding:0 0 0 5px ;
  border-bottom: 2px solid rgba(224, 224, 224, 0);

}

.header-icon:hover {
  color: white;
}
.header-icon::after {
  content: "";
  position: absolute;
  bottom: -15px;
  background: black;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  left:50%;
}
.header-icon:hover::after {
  background: white;
}
.avatar {
  background: var(--divider);
  border-radius: 50%;
  /*position: absolute;*/
  /*bottom: 12px;*/
  /*right: 0;*/
  overflow: hidden;
}

.avatar,
.avatar img {
  width: 40px;
  height: 40px;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.card {
  position: relative;
  padding: 25px;
  height: 460px;
  flex: 0 0 40%;
  max-width: 40%;
}

.card:nth-child(4n + 1),
.card:nth-child(4n + 4) {
  flex: 0 0 60%;
  max-width: 60%;
}

.card:nth-child(odd) {
  padding-left: 0;
}

.card:nth-child(even) {
  padding-right: 0;
}

.card-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 20px;
  background: #1c1c1e;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.open .card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  /*pointer-events: none;*/
}

.card-content-visit {
  background-color: rgba(0, 0, 0, 0.38);
  color: white;
  font-size: 20px;
  width: 70px;
  height: 70px;
  padding: 5px 15px;
  border-radius: 10px;
  z-index: 1;
  cursor: pointer;
}
.card-content-visit:hover {
  background-color: black;
}

.card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 420px;
  width: 100vw;
}

.open .card-image-container,
.open .title-container {
  z-index: 1;
}

.title-container {
  position: absolute;
  top: 15px;
  left: 15px;
  max-width: 300px;
  background-color: rgba(0, 0, 0, 0.43);
  padding: 10px;
  border-radius: 10px;
}

.open .title-container {
  top: 30px;
  left: 30px;
}

h2 {
  color: #fff;
  margin: 8px 0;
}

.category {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 990px;
}

.overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  transform: translateX(-50%);
}

.content-container {
  color: #fff;
  padding: 460px 35px 35px 35px;
  max-width: 700px;
  width: 90vw;
}

p {
  color: #9d9ca1;
  font-size: 20px;
  line-height: 28px;
}

@media only screen and (max-width: 800px) {
  .card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .card {
    flex: 1 0 100%;
    max-width: 100%;
    padding: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .card:nth-child(4n + 1),
  .card:nth-child(4n + 4) {
    flex: 1 0 100%;
    max-width: 100%;
  }

  .card-content-container.open {
    padding: 0;
  }
}
